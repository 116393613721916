import Vue from 'vue'
import axios from 'axios'

const vue = new Vue()

const AppState = {
  namespaced: true,
  state: {
    loading: 0,
    editing: false,
    budgetEditing: false,
    oscarColumns: {},
    latestRefresh: null,
    isHistorizing: false,
    isRefreshing: false,
  },
  getters: {
    isLoading(state) {
      return state.loading > 0;
    },
    isEdition(state) {
      return state.editing;
    },
    budgetIsEditing(state) {
      return state.budgetIsEditing
    },
    getEnv() {
      return process.env;
    },
    getOscarColumns(state) {
      return state.oscarColumns;
    },
    getLatestRefresh(state) {
      return state.latestRefresh;
    },
    getIsHistorizing(state) {
      return state.isHistorizing;
    },
    getIsRefreshing(state) {
      return state.isRefreshing;
    }
  },
  actions: {
    setLoading({ commit }, loading = false) {
      commit("SET_LOADING", loading);
    },
    setEditing({ commit }, editing = false) {
      commit("SET_EDITING", editing);
    },
    setBudgetEditing({ commit }, editing = false) {
      commit("SET_BUDGET_EDITING", editing);
    },
    loadOscarColumns({ commit }) {
      axios.get('/oscar-columns?size=1000')
        .then(response => {
          commit('LOAD_OSCAR_COLUMNS', response.data.content)
        })
    },
    loadLatestRefresh({ commit }) {
      axios.get('/latestrefresh')
        .then(response => {
          commit('LOAD_LATEST_REFRESH', response.data)
        });
    },
    historizeProjects({ commit }, payload) {
      commit('SET_IS_HISTORIZING', true)

      return axios.post(`/projects/historize`, payload)
        .then(() => {
          vue.$awn.success("L'historisation s'est terminée avec succès")
        })
        .catch(() => {
          vue.$awn.alert("Une erreur s'est produite durant l'historisation")
        })
        .finally(() => {
          commit('SET_IS_HISTORIZING', false)
        });
    },
    setRefreshing({ commit }, refreshing = false) {
      commit('SET_IS_REFRESHING', refreshing);
    },
  },
  mutations: {
    SET_LOADING(state, loading = false) {
      state.loading += loading ? 1 : -1;
    },
    SET_EDITING(state, editing = false) {
      state.editing = editing;
    },
    SET_BUDGET_EDITING(state, editing = false) {
      state.budgetIsEditing = editing;
    },
    LOAD_OSCAR_COLUMNS(state, columns) {
      state.oscarColumns = columns;
    },
    LOAD_LATEST_REFRESH(state, latestRefresh) {
      state.latestRefresh = latestRefresh;
    },
    SET_IS_HISTORIZING(state, value) {
      state.isHistorizing = value
    },
    SET_IS_REFRESHING(state, value) {
      state.isRefreshing = value;
    }
  }
};

export default AppState;
