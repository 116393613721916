<template>
  <field
    :edit="edit"
    :inline="inline"
    :label="label"
    :value="val"
    :readonly="readonly"
    :required="required"
    :type="type"
    :decimals="decimals"
  >
    <ValidationProvider :rules="appliedRules" v-slot="{ errors }">
    <input
      class="input"
      :type="inputType"
      @input="input"
      :step="type === 'percentage' ? 0.1 : 1"
      v-model.trim="val"
      :required="required"
      :name="internal_id"
      :data-vv-as="label"
      :class="{ error: errors[0]}"
      :min="min"
      :max="max"
      :cy-data="label ? 'input-' + label.replaceAll(/[ \'.]/g, '') : dataTest.replaceAll(/[ \.']/g, '')"
    >
    <span class="message is-danger nowrap">{{ errors[0] }}</span>
    </ValidationProvider>
  </field>
</template>

<script>
import uid from 'uniqid';

export default {
  name: 'text-field',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    value: {},
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: String,
      default: '',
    },
    dataTest: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    decimals: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      val: '',
      internal_id: uid(),
    };
  },
  mounted() {
    this.val = this.value;
  },
  watch: {
    value(val) {
      this.val = val;
    },
  },
  computed: {
    appliedRules () {
      let rules = this.rules

      if (this.required){
        if(this.rules !== '') {
          rules = rules + '|'
        }

        rules = rules + 'required'
      }

      return rules
    },
    inputType() {
      if(this.type === 'percentage' || this.type === 'price') return 'number'

      return this.type
    }
  },
  methods: {
    input() {
      this.$emit('input', this.val.trim());
    },
  },
};
</script>

<style lang='scss' scoped>
.input {
  max-width: 500px;
}

.error {
  border: 2px solid red;
}
</style>
