<template>
  <field
    :edit="edit"
    :inline="inline"
    :label="label"
    :value="val"
    :isCheckbox="true"
    :isChecked="isChecked"
  >
    <input
      :id="id"
      type="checkbox"
      class="switch is-rounded is-info"
      :checked="isChecked"
      @change="change(val)"
      :cy-data="'input-' + label.replaceAll(/[ \']/g, '')"
    />
    <label :for="id"></label>
  </field>
</template>

<script>
import ui from "uniqid";

export default {
  name: "checkbox-field",
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    value: {},
    checked: null,
  },
  data() {
    return {
      val: this.value,
      id: ui(),
      check: this.checked,
    };
  },
  watch: {
    value(val) {
      this.val = val;
    },
    checked(val) {
      this.check = val;
    },
  },
  computed: {
    isChecked() {
      if (typeof this.check === "object") {
        return this.check.includes(this.val);
      }
      return this.check;
    },
  },
  methods: {
    change() {
      let checked = this.check;
      if (typeof checked === "object") {
        const foundIndex = checked.indexOf(this.val);
        if (foundIndex !== -1) {
          checked.splice(foundIndex, 1);
        } else {
          checked.push(this.val);
        }
      } else {
        checked = !checked;
      }
      this.$emit("change", checked);
    },
  },
};
</script>

<style lang='scss' scoped>
</style>

