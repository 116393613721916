export const getErrorMessage = (e) => {
  if (!e.response) {
    return e.message
  }

  if (e.response && !e.response.data) {
    return e.response.status + (e.response.statusText ? ': ' + e.response.statusText : '')
  }

  return e.response.data.status + ': ' + e.response.data.message
}
