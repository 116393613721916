<template>
  <table class="table">
    <thead>
      <tr>
        <th :style="{width: '5%'}">{{ showIndex ? '#' : ''}}</th>
        <th v-for="(column, i) in columns" :key="i" :class="column.class">
          <slot :name="column.name" :columns="columns" :items="items">{{ column.title }}</slot>
        </th>
        <th v-if="hasActions" class="has-text-centered">Action</th>
      </tr>
      <slot name="extraHeaderRow" :columns="columns"></slot>
    </thead>
    <tbody>
      <slot name="firstDataRow" :items="items"></slot>
      <tr v-if="loading">
        <td class="loader-container" :colspan="columns.length + 1 + (hasActions ? 1 : 0)">
          <i class="loader"></i>
        </td>
      </tr>
      <template v-if="!loading">
        <template v-for="(item, j) in items">
          <slot name="dataRows" :item="item" :index="j">
            {{ item }}
            <slot name="action"></slot>
          </slot>
        </template>
      </template>
      <slot name="lastDataRow" :items="items"></slot>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'flexi-table',
  props: ['loader', 'columns', 'showIndex', 'hasActions'],
  data() {
    return {
      items: {},
      loading: false,
    };
  },
  watch: {
    items: {
      handler() {
        this.$emit('input', this.items);
      },
      deep: true,
    },
    columns: {
      handler() {
        this.fetch();
      },
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.loader((data) => {
        this.items = data;
        this.loading = false;
      });
    },
  },
};
</script>
<style  lang="scss" scoped>
.no-data {
  display: flex;
  justify-content: center;
}

.loader-container {
  font-size: 30px;

  .loader {
    margin: 0 auto;
  }
}
</style>
