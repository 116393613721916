import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchAccountingNatures(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/accounting-natures?${paginate.toString()}`)
      .then(res => res.data)
  },
  fetchAccountingNaturesAsList(callback) {
    axios.get('/accounting-natures').then((response) => {
      callback(response.data.content);
    });
  },
  fetchAvailableAccountingNatures(sheetTemplateId, prestationId, ids, p) {

    const paginate = new PaginationBuilder(p);

    // paginate.sort.column = 'accounting_nature_id'
    return axios.get(`/sheet-templates/${sheetTemplateId}/prestation/${prestationId}/accounting-natures?excluded=${ids.toString()}&${paginate.toString()}`)
      .then(res => res.data)
  },
};

