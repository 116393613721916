<template>
  <ValidationProvider :rules="required ? 'required' : null" v-slot="{ errors }" slim>
  <field :readonly="readonly" :edit="edit" :inline="inline" :label="label" :required="required">
    <!-- Mode read -->
    <span slot="value" v-if="val">
      <span v-if="_events.click && _events.click[0] != null">
        <a @click="clickCheck">{{ val[reference] ? val[reference] : '' }}</a>
      </span>
      <span v-else>{{ val[reference] ? val[reference] : '' }}</span>
    </span>
    <slot name="helper"></slot>

    <!-- Mode edit -->
    <span v-if="singleSelect && val && !readonly">
      <span v-if="_events.click">
        <a @click="$emit('click', val)">{{ val[reference] ? val[reference] : '' }}</a>
      </span>
      <span v-else>{{ val[reference] ? val[reference] : '' }}</span>
    </span>
    <span class="multiselect-values" v-if="!hiddenSelection && !singleSelect && value && value.length > 0">
      <div v-for="(object, index) in value" :key="index">{{ object[reference]}}</div>
    </span>
    <modal v-if="!readonly" title="Sélection" ref="selectionModal" @close="cancel" @create="emitInput(val)" :action="'Sélectionner'">
      <button v-if="!deleteonly" slot="activator" class="button is-small edit-button" :cy-data="label ? 'button-' + label.replaceAll(/[ \']/g, '') : dataTest.replaceAll(/[ \']/g, '')">
        <font-awesome-icon icon="pen" />
      </button>
      <div slot="toolbar" class="close-button" v-if="singleSelect">
        <button class="button is-medium" @click="$refs.selectionModal.close()">Fermer</button>
      </div>

      <datatable
        :single-select="singleSelect"
        :size="15"
        :fetch="fetch"
        v-model="val"
        @input="singleSelect ? emitInput($event) : null"
        :columns="columns"
        selector
        :filterColumns="hasFilter"
      ></datatable>
    </modal>
    <button
      v-if="value && value.length !== 0 && edit && !readonly && !required"
      class="button is-danger is-small delete-button"
      @click="singleSelect ? emitInput(null) : emitInput([])"
      :cy-data="label ? 'button-delete-' + label.replaceAll(/[ \']/g, '') : dataTest.replaceAll(/[ \']/g, '')"
    >
      <font-awesome-icon icon="trash-alt" />
    </button>
    <span class="message is-danger nowrap">{{ errors[0] }}</span>
    
  </field>
  </ValidationProvider>
</template>

<script>
import uid from 'uniqid';
import { mapGetters } from 'vuex';

export default {
  name: 'many2one-field',
  props: {
    entity: {
      type: [String, null],
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    fetch: Function,
    value: {},
    reference: String,
    columns: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    deleteonly: {
      type: Boolean,
      default: false,
    },
    hiddenSelection: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: uid(),
    },
    hasFilter: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      val: null,
      internal_id: uid(),
    };
  },
  mounted() {
    this.val = Array.isArray(this.value) ? JSON.parse(JSON.stringify(this.value)) : this.value;
  },
  watch: {
    value(val) {
      this.val = JSON.parse(JSON.stringify(val));
    },
  },
  computed: {
    ...mapGetters({
      getRights: 'Auth/getRights',
    }),
    singleSelect() {
      return !Array.isArray(this.val)
    }
  },
  methods: {
    emitInput(item) {
      this.$emit('input', item);
      this.$refs.selectionModal.close();
    },
    clickCheck() {
      if (this.entity && !this.getRights(this.entity).read) {
        this.$awn.warning("Vous n'avez pas les droits d'accès à cette entité.");
        return;
      }
      this.$emit('click', this.val);
    },
    cancel() {
      if(this.singleSelect) {
        return
      }
      this.val = JSON.parse(JSON.stringify(this.value));
    }
  },
};
</script>

<style lang='scss' scoped>
.close-button {
  width: 100%;
  text-align: center;
}

.value {
  padding: 0 12px;
}
.edit-button, .delete-button {
  margin-left: 5px;
}
.values-selected {
  display: flex;
  flex-direction: column;
  &>* {
    display:flex;
  }
}
.multiselect-values {
  display: inline-block;
  margin-right: 4px;
}
</style>
