<template>
  <div class="card">
    <header class="card-header" @click="toggleOpen">
      <p class="card-header-title">{{ project.name }}</p>
      <div class="card-header-icon">
        <font-awesome-icon v-if="!open" class="icon" icon="angle-down" />
        <font-awesome-icon v-else class="icon" icon="angle-up" />
      </div>
    </header>
    <div class="card-content" v-if="open">
      <div class="unread notification" v-for="(comment, index) in project.comments" :key="index" >
        <router-link :to="'/sheet/' + comment.sheet.id" class="link has-text-primary is-small" title="Se rendre dans la fiche affaire">
        {{ comment.sheet.name }}
        </router-link>
        <Comment :comment="comment" :unread="true" />
      </div>
    </div>
  </div>
</template>

<script>
import Comment from './Comment.vue'
export default {
  name:'project-comments',
  components: { Comment },
  props: {
    project: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      open: true
    }
  },
  methods: {
    toggleOpen() {
      this.open = !this.open
    }
  }
}
</script>

<style scoped lang="scss">
.card-content {
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 0 1.5rem 1.5rem;
  margin-right: -1.5em;
}

.card {
  margin-bottom: 1.5rem;
}

.card-header {
  cursor: pointer;
}

.unread{
  min-width: max-content;
  width: 31%;
  max-width: 50%;
  height: max-content;
}

.notification {
  padding: 1em;
  margin-right: 1.5em;

  a:not(.button):not(.dropdown-item) {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

</style>