import axios from 'axios'
import { getErrorMessage } from '@/lib/api/error'

export default {
  login: async (credentials) => {
    try {
      const res = await axios.post('/login', credentials)
      const token = res.data.token
      axios.defaults.headers.Authorization = `Bearer ${token}`;
      return token
    } catch (e) {
      console.error("Couldn't log in", e)
      throw new Error(getErrorMessage(e))
    }
  },
  getProfile: () => {
    return axios.get('/users/profile')
      .then(res => res.data)   
  }
};

