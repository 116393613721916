import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchUsers(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/users?${paginate.toString()}`)
      .then(res => res.data)
  },
  searchUser(name) {
    return axios.get('/users?page=0&size=50&name=' + name).then((response) => {
      return response.data.content;
    });
  }
};