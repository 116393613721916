import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Home from "@/views/Home";
import Login from "@/views/Login";

import { EntitiesName } from '../constants/Entity';

Vue.use(VueRouter);

/**
 * CORE ROUTEs
 */
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: '/users',
    name: 'UsersList',
    component: () => import('../views/users/UsersList'),
    meta: { entityName: EntitiesName.User },
  },
  {
    path: '/user/:id',
    name: 'User',
    component: () => import('../views/users/User'),
    props: true,
    meta: { entityName: EntitiesName.User },
  },
  {
    path: '/roles',
    name: 'RolesList',
    component: () => import('../views/roles/RolesList'),
    meta: { entityName: EntitiesName.Role },
  },
  {
    path: '/role/:id',
    name: 'Role',
    component: () => import('../views/roles/Role'),
    props: true,
    meta: { entityName: EntitiesName.Role },
  },
  {
    path: '/imports/sap',
    name: 'ImportSAP',
    component: () => import('../views/imports/SAP'),
    meta: { entityName: EntitiesName.ImportSAP },
  },
  {
    path: '/imports/oscar-subprojects',
    name: 'ImportOscarSubProject',
    component: () => import('../views/imports/OscarSubProjects'),
    meta: { entityName: EntitiesName.OscarSubProject },
  },
  {
    path: '/imports/oscar-subtasks',
    name: 'ImportOscarSubTask',
    component: () => import('../views/imports/OscarSubTasks'),
    meta: { entityName: EntitiesName.OscarSubTask },
  },
  {
    path: '/imports/isf',
    name: 'ImportISF',
    component: () => import('../views/imports/ISF'),
    meta: { entityName: EntitiesName.ImportISF },
  },
  {
    path: '/imports/sheets',
    name: 'ImportSheets',
    component: () => import('../views/imports/Sheets'),
    meta: { entityName: EntitiesName.Sheet },
  },
  {
    path: '/imports/reference-budgets',
    name: 'ImportReferenceBudgets',
    component: () => import('../views/imports/ReferenceBudgets'),
    meta: { entityName: EntitiesName.ReferenceBudget },
  },
  {
    path: '/imports/recettes',
    name: 'ImportRevenues',
    component: () => import('../views/imports/Revenues.vue'),
    meta: { entityName: EntitiesName.Revenue },
  },
  {
    path: '/directions',
    name: 'DirectionsList',
    component: () => import('../views/directions/DirectionsList'),
    meta: { entityName: EntitiesName.Direction },
  },
  {
    path: '/direction/:id',
    name: 'Direction',
    component: () => import('../views/directions/Direction'),
    props: true,
    meta: { entityName: EntitiesName.Direction },
  },

  {
    path: '/imputations',
    name: 'ImputationsList',
    component: () => import('../views/imputations/ImputationsList'),
    meta: { entityName: EntitiesName.Imputation },
  },
  {
    path: '/imputation/:id',
    name: 'Imputation',
    component: () => import('../views/imputations/Imputation'),
    props: true,
    meta: { entityName: EntitiesName.Imputation },
  },
  {
    path: '/oscar-column/:id',
    name: 'OscarColumn',
    component: () => import('../views/oscarColumns/OscarColumn'),
    props: true,
    meta: { entityName: EntitiesName.OscarColumn },
  },
  {
    path: '/oscar-columns',
    name: 'OscarColumnsList',
    component: () => import('../views/oscarColumns/OscarColumnsList'),
    meta: { entityName: EntitiesName.OscarColumn },
  },
  {
    path: '/oscar-subtask-name/:id',
    name: 'OscarSubTaskName',
    component: () => import('../views/oscarSubTaskNames/OscarSubTaskName'),
    props: true,
    meta: { entityName: EntitiesName.OscarSubTaskName },
  },
  {
    path: '/oscar-subtask-names',
    name: 'OscarSubTaskNamesList',
    component: () => import('../views/oscarSubTaskNames/OscarSubTaskNamesList'),
    meta: { entityName: EntitiesName.OscarSubTaskName },
  },
  {
    path: '/prestation/:id',
    name: 'Prestation',
    component: () => import('../views/prestations/Prestation'),
    props: true,
    meta: { entityName: EntitiesName.Prestation },
  },
  {
    path: '/prestations',
    name: 'PrestationsList',
    component: () => import('../views/prestations/PrestationsList'),
    meta: { entityName: EntitiesName.Prestation },
  },
  {
    path: '/projects',
    name: 'ProjectsList',
    component: () => import('../views/projects/ProjectsList'),
    meta: { entityName: EntitiesName.Project },
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: () => import('../views/projects/Project'),
    props: true,
    meta: { entityName: EntitiesName.Project },
  },
  {
    path: '/region/:id',
    name: 'Region',
    component: () => import('../views/regions/Region'),
    props: true,
    meta: { entityName: EntitiesName.Region },
  },
  {
    path: '/regions',
    name: 'RegionsList',
    component: () => import('../views/regions/RegionsList'),
    meta: { entityName: EntitiesName.Region },
  },
  {
    path: '/sheet-status/:id',
    name: 'SheetStatus',
    component: () => import('../views/sheet_status/SheetStatus'),
    props: true,
    meta: { entityName: EntitiesName.SheetStatus },
  },
  {
    path: '/sheet-status',
    name: 'SheetStatusList',
    component: () => import('../views/sheet_status/SheetStatusList'),
    meta: { entityName: EntitiesName.AdministrationStatus },
  },
  {
    path: '/administration-status/:id',
    name: 'AdministrationStatus',
    component: () => import('../views/administration_statuses/AdministrationStatus'),
    props: true,
    meta: { entityName: EntitiesName.AdministrationStatus },
  },
  {
    path: '/administration-statuses',
    name: 'AdministrationStatusList',
    component: () => import('../views/administration_statuses/AdministrationStatusList'),
    meta: { entityName: EntitiesName.AdministrationStatus },
  },
  {
    path: '/sheet/:id',
    name: 'Sheet',
    component: () => import('../views/sheets/Sheet'),
    props: true,
    meta: { entityName: EntitiesName.Sheet },
  },
  {
    path: '/sheets',
    name: 'SheetsList',
    component: () => import('../views/sheets/SheetsList'),
    meta: { entityName: EntitiesName.Sheet },
  },
  {
    path: '/sheet-template/:id',
    name: 'SheetTemplate',
    component: () => import('../views/sheetTemplate/SheetTemplate'),
    props: true,
    meta: { entityName: EntitiesName.SheetTemplate },
  },
  {
    path: '/sheet-templates',
    name: 'SheetTemplateList',
    component: () => import('../views/sheetTemplate/SheetTemplateList'),
    meta: { entityName: EntitiesName.SheetTemplate },
  },
  {
    path: '/subprestation-name/:id',
    name: 'SubPrestation',
    component: () => import('../views/subPrestationName/SubPrestationName'),
    props: true,
    meta: { entityName: EntitiesName.SubPrestationName },
  },
  {
    path: '/subprestation-names',
    name: 'SubPrestationsList',
    component: () => import('../views/subPrestationName/SubPrestationNamesList'),
    meta: { entityName: EntitiesName.SubPrestationName },
  },
  {
    path: '/synthesis/projects',
    name: 'SynthesisProjects',
    component: () => import('../views/synthesis/Projects'),
  },
  {
    path: '/synthesis/oia-correctives',
    name: 'OiaCorrectives',
    component: () => import('../views/synthesis/OiaCorrectives'),
  },
  {
    path: '/synthesis/sheet',
    name: 'SynthesisSheet',
    component: () => import('../views/synthesis/Sheet'),
  },
  {
    path: '/synthesis/comments',
    name: 'SynthesisComments',
    component: () => import('../views/synthesis/Comments'),
  },
  {
    path: "/dev",
    name: "Dev",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dev" */ "../views/Dev.vue")
  }
];

/** ROUTER SETUP */
const router = new VueRouter({
  base: __dirname,
  mode: "hash",
  scrollBehavior: () => ({ y: 0 }),
  routes
});

/**
 * ROUTER HOOKS
 */

/**
 * Check if the user has the right to read an entity
 * Redirect to the previous route if the user can't
 */
router.beforeResolve((to, from, next) => {
  // if (to.matched.some(record => record.meta.entityName)) {
  //   const entityName = to.matched.find(record => record.meta.entityName).meta
  //     .entityName;
  //   if (!store.getters["auth/getRights"](entityName).read) {
  //     // eslint-disable-next-line no-alert
  //     alert(
  //       "Vous n'avez pas les droits necessaires pour accéder à cette page."
  //     );
  //     next(false);
  //     return;
  //   }
  // }
  next();
});

router.beforeEach((to, from, next) => {
  /**
   * If we're in editing mode, we ask a confirmation before leaving the page.
   */
  if (store.getters["App/isEdition"]) {
    router.app.$awn.confirm(
      "Êtes-vous sûr de vouloir quitter la page sans enregistrer ?",
      () => {
        store.dispatch("App/setEditing", false);
        next();
      }
    );
    return;
  }

  if (to.path === "/logout") {
    store.dispatch("Auth/logout");
  }

  if (to.path === "/login") {
    if (store.getters["Auth/isAuthenticated"]) {
      router.push("/");
    }
  }

  /**
   * If the user is not authenticated, we redirect to the login page.
   */
  if (to.path !== "/login") {
    store.dispatch("Auth/checkAuth");
    if (!store.getters["Auth/isAuthenticated"]) {
      router.push("/login");
      return;
    }
  }

  next();
});

export default router;
