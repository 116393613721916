import Breadcrumb from "./Breadcrumb";
import Datatable from "./Datatable";
import DocumentManager from "./DocumentManager";
import FlexiTable from "./FlexiTable";
import Handsontable from './Handsontable';
import Paginate from "./Paginate";
import RestrictedLink from "./RestrictedLink";
import SelectAll from "./SelectAll";
import WorkflowChart from "./WorkflowChart";
import WorkflowHistory from "./WorkflowHistory";

export default {
  breadcrumb: Breadcrumb,
  datatable: Datatable,
  "document-manager": DocumentManager,
  "flexi-table": FlexiTable,
  handsontable: Handsontable,
  paginate: Paginate,
  "restricted-link": RestrictedLink,
  "select-all": SelectAll,
  "workflow-chart": WorkflowChart,
  "workflow-history": WorkflowHistory
};
