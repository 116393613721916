<template>
  <ValidationProvider :rules="appliedRules" v-slot="{ errors }">
  <field
    :edit="edit"
    :inline="inline"
    :label="label"
    :value="val | date"
    :readonly="readonly"
    :required="required"
  >
      <input
        class="input"
        type="date"
        @input="input"
        :value="val"
        :required="required"
        :name="internal_id"
        :data-test="dataTest"
        :class="{ error: errors[0] }"
        @focusout="focusout"
        v-if="!readonly"
        :cy-data="'input-' + label.replaceAll(/[ \']/g, '')"
      >
      <span class="message is-danger nowrap">{{ errors[0] }}</span>
  </field>
  </ValidationProvider>
</template>

<script>
import uid from 'uniqid';

export default {
  name: 'date-field',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {},
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    mustBeAfter: {
      type: String,
      default: '',
    },
    dataTest: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      val: '',
      internal_id: uid(),
    };
  },
  computed: {
    mustBeAfterValue() {
      return this.mustBeAfter ? this.mustBeAfter : false;
    },
    appliedRules () {
      let rules = this.rules

      if (this.required){
        if(this.rules !== '') {
          rules = rules + '|'
        }

        rules = rules + 'required'
      }

      return rules
    }
  },
  mounted() {
    if(this.value) {
      this.val = this.value.split('T')[0];
    }
  },
  watch: {
    value(val) {
      this.val = val ? val.split('T')[0] : val;
    },
  },
  methods: {
    input(val) {
      this.$emit('input', val.target.value);
    },
    focusout(val) {
      this.$emit('focusout', val.target.value);
    },
  },
};
</script>

<style lang='scss' scoped>
.input {
  max-width: 500px;
}

.error {
  border: 2px solid red;
}
</style>

