<template>
  <router-link class="has-text-info" :to="location">
    {{ text }}
    <font-awesome-icon icon="chevron-right" />
  </router-link>
</template>
<script>
export default {
  name: 'breadcrumb',
  props: {
    location: String,
    text: String,
  },
};
</script>
<style lang="scss" scoped>
.fa-xs {
  font-size: 0.75em;
}
</style>

