<template>
  <div>
    <div class="panel-heading has-background-primary">
      {{ title }}
      <span @click="openSelector"><slot name="activator"></slot></span>
    </div>

    <modal class="add-entity-button" title="Ajouter" ref="selectionModal" action="Ajouter" @create="selectItem">
      <datatable single-select :fetch="fetch" v-model="val" :columns="columns" :filterColumns="hasFilter" :size="15">
      </datatable>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'panel-header',
  props: {
    title: String,
    fetch: Function,
    reference: String,
    columns: {
      type: Object,
    },
    hasFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      val: null,
    };
  },
  methods: {
    openSelector() {
      this.$refs.selectionModal.open();
    },
    selectItem() {
      this.$emit('add', this.val);
      this.$refs.selectionModal.close();
      this.val = null;
    },
  },
};
</script>

<style lang='scss' scoped>
.close-button {
  width: 100%;
  text-align: center;
}
</style>
