<template>
  <div
    class="navbar is-fixed-top is-info"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand has-background-white">
      <router-link to="/" class="navbar-item logo">
        <img src="@/assets/images/logo-axione.png" />
      </router-link>

      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarLeft"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarLeft" class="navbar-menu">
      <div class="navbar-start" v-if="user.role">
        <div
          class="navbar-item has-dropdown is-hoverable"
          v-for="(mainmenu, index) in user.role.menus"
          :key="index"
        >
          <a
            class="navbar-link is-arrowless"
            :data-test="`sel-nav-${mainmenu.title}`"
          >
            {{ mainmenu.title }}
          </a>

          <div class="navbar-dropdown">
            <router-link
              :to="menu.path"
              class="navbar-item"
              :data-test="`sel-nav-${menu.title}`"
              v-for="(menu, idx) in mainmenu.menus"
              :key="idx + '_' + $route.name"
            >
              {{ menu.title }}
            </router-link>
          </div>
        </div>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="tag is-warning" v-if="isHistorizing">
            <span class="loader"></span>
            <span class="">Historization en cours </span>
          </div>
          <div class="tag is-warning" v-if="isRefreshing">
            <span class="loader"></span>
            <span class="">Rafraichissement des données en cours </span>
          </div>
        </div>

        <div class="navbar-item">
          <span v-if="user.role" class="tag is-warning">{{
            user.role.name
          }}</span>
        </div>

        <!--
        <div class="navbar-item">
          <i class="fa fa-th"></i>
        </div>
        -->

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link is-arrowless">
            <span class="avatar">{{
              user && user.name ? user.name[0] : "A"
            }}</span>
            {{ user ? user.name : "" }}
          </a>

          <div class="navbar-dropdown">
            <a class="navbar-item" @click="refreshDatabase" v-if="options.includes($UserOptions.DATABASE.REFRESH)">
              Rafraîchir les données
            </a>
            <router-link to="/logout" class="navbar-item">
              Déconnexion
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from "vuex";

export default {
  name: "Navbar",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "Auth/isAuthenticated",
      user: "Auth/getUser",
      options: 'Auth/getOptions',
      isHistorizing: 'App/getIsHistorizing',
      isRefreshing: 'App/getIsRefreshing'
    }),
  },
  mounted() {
    this.pullStatus();
    setInterval(() => this.pullStatus(), 60000);
  },
  methods: {
    refreshDatabase() {
      axios.get(`/updatecomputeddata`).then((response) => {
        if (response.data.success) {
          this.$store.dispatch("App/loadLatestRefresh");
          this.$store.dispatch("App/setRefreshing", true);
        }
      });
    },
    pullStatus() {
      if(!this.isAuthenticated) {
        return;
      }

      axios.get('/tasks-status')
        .then(response => {
          this.$store.dispatch("App/setRefreshing", response.data.isRefreshing);
        })
        .catch(() => {
          // Ignore the error
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.logo {
  padding: 5px 15px;
  img {
    height: 35px;
    max-height: 52px;
  }
}
.navbar-dropdown {
  z-index: 102;
}
.navbar-item {
  padding-left: 10px;
  padding-right: 10px;
  .tag {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
  }

  .avatar {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    padding-top: 3px;
    border-radius: 100%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    background-color: var(--axione-orange);
  }
}
</style>
