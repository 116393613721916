<template>
  <ValidationProvider :rules="appliedRules" v-slot="{ errors }" slim>
  <field :edit="edit" :inline="inline" :label="label" :required="required">
    <span slot="value" v-if="val" class="textarea-container">
      <nl2br tag="div" :text="val"/>
    </span>
    <textarea
      class="textarea"
      :class="{ error : errors[0] }"
      @input="$emit('input', $event.target.value.trim())"
      v-model="val"
      :name="internal_id"
      :required="required"
      :data-test="dataTest"
      :readonly="readonly"
      :cy-data="'textarea-' + label.replaceAll(/[ \']/g, '')"
    ></textarea>
    <span class="message is-danger nowrap">{{ errors[0] }}</span>
  </field>
  </ValidationProvider>
</template>

<script>
import uid from 'uniqid';

export default {
  name: 'textarea-field',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {},
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      val: '',
      // eslint-disable-next-line
      internal_id: uid()
    };
  },
  mounted() {
    this.val = this.value;
  },
  watch: {
    value(val) {
      this.val = val;
    },
  },
  computed: {
    appliedRules () {
      let rules = this.rules

      if (this.required){
        if(this.rules !== '') {
          rules = rules + '|'
        }

        rules = rules + 'required'
      }

      return rules
    }
  },
};
</script>

<style lang='scss' scoped>
.textarea {
  min-width: 100px;
  display: inline-block;
}

.textarea-container {
  display: inline-block;
  min-height: 100px;
  border: 1px solid #eee;
  padding: 5px 10px;
  min-width: 350px;
  width: 100%;
}

.error {
  border: 2px solid red;
}
</style>
