<template>
  <component :is="container">
    <router-link :class="linkClass" :to="to" v-if="entity && getRights(entity) && getRights(entity).read">
      <slot></slot>
    </router-link>
    <slot v-else></slot>
  </component>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'restricted-link',
  props: {
    entity: {
      default: null,
      type: String,
    },
    to: {
      default: '',
      type: String,
    },
    container: {
      default: 'div',
      type: String,
    },
    linkClass: {
      default: '',
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      getRights: 'Auth/getRights',
    }),
  },
};
</script>
