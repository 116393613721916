import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchOscarColumns(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/oscar-columns?${paginate.toString()}`).then(response => response.data);
  },
  fetchOscarColumnsAsList(callback) {
    axios.get('/oscar-columns?size=1000').then((response) => {
      callback(response.data.content);
    });
  }
};