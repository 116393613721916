<template>
  <div class="header">
    <slot></slot>

    <i v-if="loading" class="loader inline"></i>

    <div class="is-pulled-right">
      <slot name="actions"></slot>
      <button v-if="create && getRights(entity).create" class="button is-warning" :cy-data="`cy-${entity}-create`" @click="create">Créer</button>
      <button v-if="edit && !editMode && getRights(entity).update" class="button is-info" :cy-data="`cy-${entity}-edit`" @click="edit">Édition</button>
      <button v-if="cancel && editMode" class="button is-warning" :cy-data="`cy-${entity}-cancel`" @click="cancel">Annuler</button>
      <button v-if="save && editMode && getRights(entity).update" class="button is-success" :cy-data="`cy-${entity}-save`" :class="{'is-loading': isLoading}" @click="triggerSave">Sauvegarder</button>
      <button v-if="remove && getRights(entity).delete" :cy-data="`cy-${entity}-delete`" class="button is-danger" @click="remove">
        <font-awesome-icon icon="trash-alt" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'page-header',
  props: {
    entity: String,
    create: Function,
    edit: Function,
    cancel: Function,
    save: Function,
    remove: Function,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      editMode: 'App/isEdition',
      getRights: 'Auth/getRights',
      loading: 'App/isLoading',
    }),
  },
  methods: {
    triggerSave() {
      this.isLoading = true;

      const p = this.save();

      if (p) {
        p.finally(() => {
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.inline {
  display: inline-block;
  border-left-color: #0195b7;
  border-bottom-color: #0195b7;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .loader {
    margin-right: auto;
    margin-left: 10px;
  }
}

.is-pulled-right {
  display: flex;
  & > * {
    margin-left: 3px;
  }
}
</style>
