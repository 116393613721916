<template>
  <div>
    <div class="chart-container">
      <svg ref="svg" class="chart"></svg>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import dagreD3 from 'dagre-d3';
import axios from 'axios';

export default {
  name: 'workflow-chart',
  props: {
    id: [String, Number],
  },
  data() {
    return {
      chart: {},
    };
  },
  mounted() {
    this.fetchGraph();
  },
  watch: {
    id() {
      this.fetchGraph();
    },
  },
  methods: {
    fetchGraph() {
      axios.get(`/workflow/${this.id}/flowchart`).then((response) => {
        this.chart = response.data;
        this.renderChart();
      });
    },
    renderChart() {
      const nodeType = {
        1: 'start',
        2: 'normal',
        3: 'error',
        4: 'end',
      };

      const g = new dagreD3.graphlib.Graph()
        .setGraph({})
        .setDefaultEdgeLabel(() => {});

      const nodeIdList = [];

      this.chart.nodes.forEach((node) => {
        let nodeName = node.name;

        const nodeURL = this.$router.resolve({ name: 'WorkflowNode', params: { id: node.id } }).href;

        nodeName = `<a href="${nodeURL}" onclick="window.$router.push({ name: 'WorkflowNode', params: { id: ${node.id} } })"><div>${nodeName}</div><div class="count">${node.count}</div></a>`;

        nodeIdList.push(node.id);

        g.setNode(node.id, {
          label: nodeName,
          labelType: 'html',
          class: `type-${nodeType[node.node_type]}`,
          rx: 10,
          ry: 10,
        });
      });

      this.chart.transitions.forEach((transition) => {
        if (!nodeIdList.includes(transition.fromNode.id)) {
          nodeIdList.push(transition.fromNode.id);
          g.setNode(transition.fromNode.id, {
            label: '...',
            labelType: 'html',
            class: 'type-unknown',
            rx: 10,
            ry: 10,
          });
        }

        if (!nodeIdList.includes(transition.toNode.id)) {
          nodeIdList.push(transition.toNode.id);
          g.setNode(transition.toNode.id, {
            label: '...',
            labelType: 'html',
            class: 'type-unknown',
            rx: 10,
            ry: 10,
          });
        }

        g.setEdge(transition.fromNode.id, transition.toNode.id, {
          label: transition.name,
          lineInterpolate: 'basis',
          curve: d3.curveBasis,
        });
      });

      // eslint-disable-next-line
      const dagreRender = new dagreD3.render();

      this.$refs.svg.innerHTML = '';
      const svg = d3.select(this.$refs.svg);
      const svgGroup = svg.append('g');

      dagreRender(svgGroup, g);

      svg.attr('height', g.graph().height + 5);
      svg.attr('width', g.graph().width + 5);
    },
    nodeDoesExist(nodeId) {
      return this.chart.nodes.some(n => n.id === nodeId);
    },
  },
};
</script>

<style lang='scss'>
.chart-container {
  margin: 0 auto;
  padding: 25px;
  background-color: #fff;
  width: auto;
}

.chart {
  display: block;
  margin: 0 auto;
}

.chart text {
  font-weight: 300;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serf;
  font-size: 14px;
}

.chart .node rect {
  stroke: #333;
  fill: #fff;
}

.chart .node text {
  pointer-events: none;
}

.chart .label a {
  color: #000;
  font-weight: normal;
}

.chart .label .count {
  text-align: center;
  color: #000;
  font-weight: bold;
}

.chart g.type-start > rect {
  fill: #ccf;
}

.chart g.type-normal > rect {
  fill: #fff;
}

.chart g.type-error > rect {
  fill: #fcc;
}

.chart g.type-end > rect {
  fill: #cfc;
}

.chart g.type-unknown > rect {
  fill: #ccc;
}

.chart .edgePath path.path {
  stroke: #333;
  fill: none;
  stroke-width: 1.5px;
}

</style>
