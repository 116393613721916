import Field from "./Field";
import CheckboxField from "./CheckboxField";
import DateField from "./DateField";
import Many2OneField from "./Many2OneField";
import SelectorField from "./SelectorField";
import TextareaField from "./TextareaField";
import TextField from "./TextField";
import PercentageField from './PercentageField';

export default {
  field: Field,
  "checkbox-field": CheckboxField,
  "date-field": DateField,
  "many2one-field": Many2OneField,
  "selector-field": SelectorField,
  "textarea-field": TextareaField,
  "text-field": TextField,
  'percentage-field': PercentageField
};
