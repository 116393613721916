export const States = Object.freeze({
  DRAFT: 'draft',
  REVIEWED: 'reviewed',
  VALIDATED: 'validated'
});

export const Colors = Object.freeze({
  draft: 'is-warning',
  reviewed: 'is-info',
  validated: 'is-success'
});
