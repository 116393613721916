<template>
  <div class="is-block is-full-width">
    <div class="controls is-pulled-right">
      <button class="button is-primary white" @click.stop="openUploadModal" data-test="sel-document-upload">
        Upload&nbsp;
        <font-awesome-icon icon="upload" />
      </button>
    </div>
    <div class="view">
      <flexi-table
        class="is-full-width"
        :loader="fetchDocuments"
        :columns="documentTableColumns"
        :showIndex="true"
        v-model="documents"
        ref="documentsTable"
        :hasActions="true"
      >
        <template slot="dataRows" slot-scope="{ item, index }">
          <tr v-if="documents.length === 0">
            <td style="text-align: center;" colspan="6">Aucun document :)</td>
          </tr>
          <tr>
            <td>{{ index + 1 }}</td>
            <td>{{ item.filename }}</td>
            <td>{{ item.type && item.type.name }}</td>
            <td>{{ item.createdAt | reverseDate }}</td>
            <td>
              <div class="is-pulled-right">
                <span>
                  <form
                    class="inline-form"
                    method="POST"
                    target="_blank"
                    :action="`${API}/document/${item.id}`"
                  >
                  <button class="button is-success" type="submit">
                    Télécharger&nbsp;
                    <font-awesome-icon icon="download" />
                  </button>
                  <input type="hidden" name="token" :value="authToken">
                </form>
                </span>
                <button v-if="deletable" class="button is-danger" @click="deleteDocument(item)">
                  <font-awesome-icon icon="trash-alt" />
                </button>
              </div>
            </td>
          </tr>
        </template>
      </flexi-table>
    </div>
    <modal
      ref="createDocumentModal"
      @create="uploadDocument"
      @close="closeModal"
      title="Ajout d'un document"
    >
      <div class="field">
        <button class="button is-primary" @click.stop="addFile">
          Sélectionner un fichier&nbsp;
        </button>
      </div>
      <field
        readonly
        inline
        label="Nom du fichier sélectionné"
      >{{ selectedFileName }}</field>
      <input
        ref="documentFileInput"
        type="file"
        name="documentFile"
        accept="*/*"
        data-test="sel-document-file-input"
        @change="selectFile"
      >
      <many2one-field
        label="Type de document"
        v-model="createDocument.documentType"
        :fetch="$api.fetchDocumentTypes"
        reference="name"
        :columns="documentTypeMany2OneColumns"
        :edit="true"
        data-test="sel-document-upload-type"
      ></many2one-field>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'document-manager',
  props: {
    objectName: String,
    // eslint-disable-next-line
    objectId: String | Number,
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      documents: [],
      createDocument: {
        documentType: null,
        file: null,
      },
      API: process.env.API_URL,
      authToken: this.$store.getters['auth/getToken'],
      documentTableColumns: [
        {
          title: 'Nom de fichier',
          name: 'filename',
          accessor: 'filename',
          filter: '',
        },
        {
          title: 'Type',
          name: 'type',
          accessor: 'type.name',
          filter: '',
        },
        {
          title: 'Date',
          name: 'date',
          accessor: 'createdAt',
          filter: '',
        },
      ],
      documentTypeMany2OneColumns: {
        name: 'Nom',
      },
      isLoading: false,
    };
  },
  computed: {
    selectedFileName() {
      if (this.createDocument.file) {
        return this.createDocument.file.name;
      }
      return ' ';
    },
  },
  methods: {
    fetchDocuments(callback) {
      axios
        .get(`/documents?entity=${this.objectName}&id=${this.objectId}`)
        .then((res) => {
          if (typeof callback === 'function') {
            callback(res.data);
          }
        });
    },
    uploadDocument() {
      const form = new FormData();
      const { documentType, file } = this.createDocument;
      if (documentType === null || file === null) {
        // eslint-disable-next-line
        alert("Vous n'avez pas sélectioné de type ou de fichier");
        return;
      }
      form.set('file', this.createDocument.file, this.createDocument.file.name);
      form.set('entity', this.objectName);
      form.set('id', this.objectId);
      form.set('type', this.createDocument.documentType.id);
      axios
        .post('document', form)
        .then(() => {
          this.$refs.createDocumentModal.close();
        })
        .finally(() => {
          this.$refs.documentsTable.fetch();
        });
    },
    openUploadModal() {
      this.$refs.createDocumentModal.open();
    },
    addFile() {
      this.$refs.documentFileInput.click();
    },
    selectFile() {
      this.createDocument.file = this.$refs.documentFileInput.files[0];
    },
    closeModal() {
      this.createDocument = {
        documentType: null,
        file: null,
      };
    },
    deleteDocument(document) {
      return this.$awn.confirm(
        'Êtes-vous sûr de vouloir supprimer ce document ?',
        () =>
          axios.delete(`/document/${document.id}`).then((response) => {
            if (response.data.success) {
              this.$refs.documentsTable.fetch();
            }
          }),
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.is-full-width {
  width: 100%;
  flex: 1;
}

.white {
  color: #fff;
}

.field {
  margin-bottom: 0.75em;
}

.inline-form {
  display: inline;
}
</style>

