/**
 * LIBS
 */
import dayjs from "dayjs";
import "dayjs/locale/fr";
import moment from 'moment';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { min, required } from 'vee-validate/dist/rules';
import Vue from "vue";
import VueAWN from "vue-awesome-notifications";
import Fragment from 'vue-fragment';
import Nl2br from "vue-nl2br";
Vue.use(Fragment.Plugin)

import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";

import "handsontable/dist/handsontable.full.css";

/**
 * PROJECT MODULES
 */
import App from "./App";
import api from "./lib/api";
import "./plugins/axios";
import router from "./router";
import store from "./store";

/**
 * ERROR MANAGEMENT
 */
const APP_URL = process.env.VUE_APP_APP_URL || "";
const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN || null;
if (SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [APP_URL, /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE || 1.0,
  });
}

/**
 * ASSETS
 */
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleUp,
  faArrowUp,
  faCalendarCheck,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCloudDownloadAlt,
  faComment,
  faDownload,
  faEllipsisH,
  faExchangeAlt,
  faGripHorizontal,
  faInfo,
  faKey,
  faMinus,
  faPen,
  faPercentage,
  faPlus,
  faRedoAlt,
  faSearch,
  faSortDown,
  faSortUp,
  faTag,
  faTimes,
  faTrashAlt,
  faUpload,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { EntitiesName } from "./constants/Entity";
import { Options } from "./constants/Role";
import { Colors, States } from "./constants/State";
library.add(
  faChevronRight,
  faChevronLeft,
  faCheck,
  faExchangeAlt,
  faRedoAlt,
  faEllipsisH,
  faUpload,
  faDownload,
  faCloudDownloadAlt,
  faTrashAlt,
  faTimes,
  faUser,
  faPen,
  faKey,
  faPlus,
  faMinus,
  faSearch,
  faAngleDown,
  faCalendarCheck,
  faInfo,
  faTag,
  faComment,
  faAngleUp,
  faSortUp,
  faSortDown,
  faGripHorizontal,
  faArrowUp,
  faPercentage
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

/**
 * BUEFY
 */
import { Autocomplete, ConfigProgrammatic, Select, Table, Tooltip } from 'buefy';
Vue.use(Tooltip)
Vue.use(Autocomplete)
Vue.use(Table)
Vue.use(Select)

ConfigProgrammatic.setOptions({
  defaultIconComponent: "font-awesome-icon",
  defaultIconPack: "fas",
});

/**
 * COMPONENTS
 */
import elements from "./components/elements";
import fields from "./components/fields";
import layout from "./components/layout";
// NOTE add new groups here to extend

// take each component group
[elements, fields, layout].forEach((group) => {
  Object.entries(group).forEach((entry) => {
    // set up a component for each entry
    const [name, component] = entry;
    Vue.component(name, component);
  });
});

/**
 * THIRD-PARTY CONFIG
 */

Vue.use(VueAWN, {
  labels: {
    success: "Succès",
    warning: "Attention",
    alert: "Erreur",
    confirm: "Veuillez confirmer votre choix",
    confirmCancel: "Annuler",
  },
}); // Notificiations
Vue.component("nl2br", Nl2br); // Text formatting

/**
 * FILTERS
 */

// DATES
Vue.filter("fromNow", (value) => dayjs(value).fromNow());
Vue.filter("datetime", (value) =>
  value ? dayjs(value).format("DD/MM/YYYY HH:mm:ss") : ""
);
Vue.filter("date", (value, format) =>
  value
    ? format
      ? dayjs(value).format(format)
      : dayjs(value).format("DD/MM/YYYY")
    : ""
);
Vue.filter("reverseDate", (value) => dayjs(value).format("DD/MM/YYYY"));
Vue.filter("monthYear", (value) => {
  const date = dayjs(value, { locale: "fr" }).format("MMMM YYYY");

  return date[0].toUpperCase() + date.substring(1);
});

Vue.filter("monthName", (value) =>
  moment("2022-" + value + "-01 00:00:00").format("MMM")
);

// CURRENCIES
Vue.filter("twoSF", (value) => value.toFixed(2));
Vue.filter("priceEUR", (value, digits = 2) =>
  new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value)
);
Vue.filter("number", (value, digits = 0) =>
  !isNaN(value)
    ? new Intl.NumberFormat("fr-FR", {
        style: "decimal",
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      }).format(value)
    : ""
);
//PERCENTAGE
Vue.filter("percentage", (value, digits = 0) =>
  new Intl.NumberFormat("fr-FR", {
    style: "percent",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value)
);

/**
 * EXTRA VM
 */
Vue.prototype.$Api = api;
Vue.prototype.$EntitiesName = EntitiesName;
Vue.prototype.$UserOptions = Options;
Vue.prototype.$Colors = Colors;
Vue.prototype.$States = States;

/**
 * Validations
 */
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
extend("required", {
  ...required,
  message: "Ce champ est obligatoire",
});
extend("min", {
  ...min,
  message: (_, { length }) =>
    "Ce champ doit comporter un minimum de " + length + " caractères",
});

/**
 * GO!
 */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
