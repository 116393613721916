import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchOscarSubProjects(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/oscar-subprojects?${paginate.toString()}`).then(response => response.data);
  },
  fetchOscarSubProjectsAvailable(projectId, p) {
    const paginate = new PaginationBuilder(p);
    paginate.sort.column = 'id'
    return axios.get(`/projects/${projectId}/oscar-subprojects/available?${paginate.toString()}`).then(response => response.data);
  },
};