<template>
  <section class="login-wrapper has-background-white">
    <div class="hero-body">
      <div class="container">
        <div class="logo">
          <img src="@/assets/images/logo-axione.png" />
          <h1 class="title">Outil de Contrôle Budgétaire</h1>
        </div>

        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <div v-if="error" class="notification is-danger">
              <button class="delete" @click="error = null"></button>
              {{ error.message }}
              <p>
                {{ error.status }}
              </p>
            </div>

            <form @submit.prevent="connect">
              <div class="field">
                <label class="label">Identifiant</label>
                <div class="control has-icons-left has-icons-right">
                  <input
                    class="input"
                    type="text"
                    name="login"
                    placeholder="Identifiant"
                    v-model="auth.username"
                  />
                  <span class="icon is-small is-left">
                    <font-awesome-icon icon="user" />
                  </span>
                </div>
              </div>

              <div class="field">
                <label class="label">Mot de passe</label>
                <div class="control has-icons-left has-icons-right">
                  <input
                    class="input"
                    type="password"
                    name="password"
                    placeholder="Mot de passe"
                    v-model="auth.password"
                  />
                  <span class="icon is-small is-left">
                    <font-awesome-icon icon="key" />
                  </span>
                </div>
              </div>

              <div class="field is-grouped submit">
                <div class="control">
                  <button
                    class="button is-primary"
                    :class="{ 'is-loading': loading }"
                    @disabled="loading"
                  >
                    Connexion
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      auth: {
        username: "",
        password: "",
      },
      error: null,
      loading: false,
    };
  },
  methods: {
    async connect() {
      this.loading = true;
      try {
        const token = await this.$Api.Auth.login(this.auth);
        const user = await this.$Api.Auth.getProfile();

        this.$store.commit("Auth/setAuth", {
          token,
          user,
        });
        this.$router.push({ name: "Home" });
      } catch (e) {
        console.error("Error: ", e);
        this.error = {
          status: e.message,
          message:
            "Anomalie dans la fiche utilisateur - Aucun projet n'est rattaché à l'utilisateur. Contacter un Admin !",
        };
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.logo {
  text-align: center;
  margin: -100px auto 20px auto;
}

.submit .control {
  margin: 20px auto;
}

.login-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
