<template>
  <div class="home">
    <div class="container is-fluid">
      <h1 class="title">OCB Dashboard</h1>
      <div class="title-actions">
        <div class="subtitle is-4 has-text-info">Evolutions FDC</div>
        <div class="field has-addons">
          <p class="control">
            <a
              class="button"
              :class="{ 'is-primary': perimeter === 'projects' }"
              @click="setPerimeter('projects')"
              >Mes projets</a
            >
          </p>

          <p class="control">
            <a
              class="button"
              :class="{ 'is-primary': perimeter === 'sheets' }"
              @click="setPerimeter('sheets')"
              >Mes fiches affaires</a
            >
          </p>
        </div>
      </div>
      <div class="panel-block evolutions">
        <table class="table">
          <thead>
            <tr>
              <th class="nowrap invisible is-info">Nom budget</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="is-info">Recettes</td>
            </tr>
            <tr>
              <td class="is-info">Débours</td>
            </tr>
            <tr>
              <td class="is-info">Marge</td>
            </tr>
            <tr>
              <td class="is-info">% Marge</td>
            </tr>
          </tbody>
        </table>
        <table
          class="table is-fullwidth budget"
          v-for="(budget, index) in evolutions[perimeter]"
          :key="index"
        >
          <thead>
            <tr>
              <th class="has-text-centered nowrap" :cy-data="'budget-' + index">
                {{ budget.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="has-text-centered">
                {{ budget.incomes | priceEUR }}
              </td>
            </tr>
            <tr>
              <td class="has-text-centered">
                {{ budget.expenses | priceEUR }}
              </td>
            </tr>
            <tr>
              <td class="has-text-centered">
                {{ budget.margin | priceEUR }}
              </td>
            </tr>
            <tr>
              <td class="has-text-centered">
                {{ budget.marginPercentage | percentage(2) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="">
        <div class="title-actions subtitle is-4 has-text-info">
          Messages non lus
        </div>
        <ProjectComments
          v-for="(project, projectName) in projects"
          :key="projectName"
          :project="project"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectComments from "../components/elements/ProjectComments.vue";

export default {
  name: "Home",
  components: {
    ProjectComments,
  },
  data() {
    return {
      projects: null,
      evolutions: {},
      perimeter: "projects",
    };
  },
  mounted() {
    this.$Api.Dashboard.fetchUnreadComments().then((res) => {
      let projects = {};
      res.forEach((comment) => {
        if (!projects[comment.sheet.project.name]) {
          projects[comment.sheet.project.name] = {
            name: comment.sheet.project.name,
            comments: [],
          };
        }
        projects[comment.sheet.project.name].comments.push(comment);
      });
      this.projects = projects;
    });

    this.fetchEvolutions();
  },
  methods: {
    fetchEvolutions() {
      this.$Api.Dashboard.fetchEvolutions().then(
        (evolutions) => (this.evolutions = evolutions)
      );
    },
    setPerimeter(perimeter) {
      this.perimeter = perimeter;
    },
  },
};
</script>

<style scoped lang="scss">
.title-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}
.table:last-child {
  margin-bottom: 1.5rem;
}
.evolutions {
  overflow-x: auto;
  th {
    padding: 8px 36px;
  }
}
</style>
