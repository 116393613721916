export const Options = Object.freeze({
  USERS: {
    SEE_DEFAULT: "users.can_see_default",
  },
  BUDGETS: {
    VALIDATE: "budgets.can_validate",
    SET_QUANTITIES: "budgets.can_set_cb_quantities",
  },
  BUDGET_CONTROLS: {
    REVIEW: "budget_controls.can_review",
    VALIDATE: "budget_controls.can_validate",
    HISTORIZE: "budget_controls.can_historize",
  },
  DATABASE: {
    REFRESH: "database.can_refresh",
  },
  PROJECTS: {
    CLONE: "projects.can_clone",
  },
  FORECASTS: {
    EDIT: "forecasts.can_edit",
  },
});
