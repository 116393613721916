import axios from "axios";

export default {
  fetchAllReferenceBudgets(perimeter='projects', callback) {
    return axios.get(`/reference-budgets/dashboard?perimeter=${perimeter}`)
      .then(response => {
        return callback(response.data)
      });
  },
  fetchReferenceBudgetsForSheet(id, callback) {
    return axios.get(`/sheets/${id}/reference-budgets`)
      .then(response => {
        return callback(response.data)
      });
  },
  fetchBudgetControlsForSheet(id, callback) {
    axios.get(`/sheets/${id}/budget-controls`)
      .then(response => {
        return callback(response.data)
      });
  },
  fetchAvailableMilestonesForSheet(id, callback) {
    let milestones = [
      'Transfert',
      'Objectif',
      'Point 20',
      'Point 50',
      'Point 80',
      'Point 100'
    ];

    axios.get(`/sheets/${id}/reference-budgets/names`)
      .then(response => {
        milestones = milestones.filter(name => !response.data.includes(name))

        if (typeof callback === 'function') {
          return callback(milestones);
        }
        return milestones
      });
    
  },
  fetchNamesForSheet(id, callback) {
    axios.get(`/sheets/${id}/reference-budgets/names`)
      .then(response => {
        if (typeof callback === 'function') {
          return callback(response.data);
        }
        return response.data
      });
    
  },
  resetRad(referenceBudgetId) {
    return axios.put(`/reference-budgets/${referenceBudgetId}/reset`)
  },
}