import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchCategories(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/categories?${paginate.toString()}`)
      .then(res => res.data)
  },
  fetchParentCategories(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/categories/parent?${paginate.toString()}`)
      .then(response => response.data);
  },
  fetchSubCategories(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/categories/child?${paginate.toString()}`)
      .then(response => response.data);
  },
  fetchSubCategoriesForParent(p, id = '') {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/categories?parent.id=${id}&${paginate.toString()}`)
      .then(response => response.data);
  },
};