<template>
  <div class="comment">
    <div class="comment-area">{{ comment.message }}</div>
    <div class="actions">
      <button
        class="button is-small is-danger"
        v-if="comment.creator.uid === currentUser.uid && !comment.hasReplies"
        @click="deleteComment"
      >
        Supprimer
      </button>
      <button
        class="button is-small is-info"
        v-if="!displayReplies && comment.hasReplies"
        @click="toggleDisplayReplies"
      >
        Afficher la discussion
        <div class="dot" v-if="!read"></div>
      </button>
      <button
        class="button is-small is-info"
        v-if="displayReplies"
        @click="toggleDisplayReplies"
      >
        Masquer la discussion
      </button>
      <button class="button is-small is-info" @click="toggleIsCommenting">
        Répondre
      </button>
    </div>
    <CommentForm
      v-if="isCommenting"
      :parentId="comment.id"
      reply
      @cancel="toggleIsCommenting"
      @added="commented"
    />
    <div v-if="displayReplies" class="replies">
      <div
        class="reply"
        v-for="(reply, idx) in replies"
        :key="'repl_' + comment.id + idx"
      >
        <div class="reply-info">
          <p class="">
            {{ reply.createdAt | date("DD/MM/YYYY HH:mm") }} -
            <strong>{{ reply.creator.name }}</strong>
          </p>
          <button
            class="button is-small is-danger delete-reply-btn"
            v-if="reply.creator.uid === currentUser.uid"
            @click="deleteReply(reply.id)"
          >
            Supprimer
          </button>
        </div>
        <div class="reply-comment comment-area">{{ reply.message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CommentForm from "./CommentForm.vue";
import { mapGetters } from "vuex";

export default {
  components: { CommentForm },
  name: "comment",
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
    unread: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCommenting: false,
      displayReplies: false,
      replies: null,
      read: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "Auth/getUser",
    }),
  },
  mounted() {
    if (this.unread) this.read = false;
  },
  watch: {
    replies(val) {
      if (val.length > 0) {
        this.comment.hasReplies = true;
      } else {
        this.comment.hasReplies = false;
      }
    },
  },
  methods: {
    loadReplies() {
      this.$Api.Comment.fetchReplies.bind(
        this,
        this.comment.id
      )((data) => {
        this.replies = data;
      });
    },
    toggleIsCommenting() {
      this.isCommenting = !this.isCommenting;
    },
    toggleDisplayReplies() {
      if (!this.diplayReplies) {
        this.loadReplies();
      }
      this.displayReplies = !this.displayReplies;
      this.read = true;
    },
    commented() {
      this.loadReplies();
      this.toggleIsCommenting();
      this.diplayReplies = true;
    },
    deleteComment() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer ce commentaire ?",
        () =>
          axios.delete(`/comments/${this.comment.id}`).then((res) => {
            if (res.data.success) {
              this.$emit("deleted");
            }
          })
      );
    },
    deleteReply(id) {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer ce commentaire ?",
        () =>
          axios.delete(`/comments/${id}`).then((res) => {
            if (res.data.success) {
              this.loadReplies();
            }
          })
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-area {
  background-color: white;
  padding: 10px;
  border-radius: 4px;
}
.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  & > * {
    margin-left: 5px;
  }
}
.replies {
  padding-left: 40px;
}
.reply {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.reply-info {
  font-size: small;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
  }
}

.delete-reply-btn {
  height: 1.7em;
}

.dot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  right: -4px;
  bottom: 17px;
}
</style>
