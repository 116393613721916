import axios from "axios";

export default {
  fetchEvolutions() {
    return axios.get(`/dashboard/evolutions`)
      .then(response => response.data);
  },
  fetchUnreadComments() {
    return axios.get('/dashboard/comments')
      .then((response) => response.data);
  },
};
