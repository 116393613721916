import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchPrestations(p) {
    const paginate = new PaginationBuilder(p);
    paginate.sort.column = 'category.parent.name'
    return axios.get(`/prestations?${paginate.toString()}`)
      .then(res => res.data)
  },
  fetchPrestationsForSheet(id, callback) {
    axios.get(`/sheets/${id}/prestations`)
      .then(response => {
        // let data = groupByPrestation(response.data)
        return callback(response.data)
      });
  },
  fetchSubPrestations(id, p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/projects/${id}/prestations?${paginate.toString()}`)
      .then(res => res.data)
  },
  fetchOscarColumns(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/oscar-columns?${paginate.toString()}`)
      .then(res => res.data)
  },
};

