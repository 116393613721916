import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchRegions(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/regions?${paginate.toString()}`).then(response => response.data);
  },
  fetchRegionsAsList(callback) {
    axios.get('/regions').then((response) => {
      callback(response.data.content);
    });
  }
};