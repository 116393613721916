import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchProjects(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/projects?${paginate.toString()}`).then(response => response.data);
  },
  fetchProjectsAsList(callback) {
    axios.get('/projects').then((response) => {
      callback(response.data.content);
    });
  },
  fetchSubPrestationsForProject(id, callback) {
    axios.get(`/projects/${id}/prestations`)
      .then(response => {
        let data = groupBySubPrestation(response.data)
        return callback(data)
      });
  },
  fetchImputationsForProject(id, p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/projects/${id}/imputations?${paginate.toString()}`)
      .then(res => res.data)
  },
  fetchImputationsAvailableForOrder(projectId, ids, p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/projects/${projectId}/imputations?excluded=${ids.toString()}&${paginate.toString()}`)
      .then(res => res.data)
  },
  fetchActivities(callback) {
    const activities = {
      1: 'Avancement physique',
      2: 'Avancement financier (PVA)'
    };
    if (typeof callback === 'function') {
      callback(activities);
    }
    return activities;
  },
  fetchProjectsNames(callback) {
    axios.get('/projects').then((response) => {
      let data = [];
      response.data.content.forEach(project => data.push({
        id: project.id,
        name: project.name
      }));
      callback(data);
    });
  }
};



function groupBySubPrestation(data) {
  let prestations = {}

  data.forEach(p => {
    const index = p.prestation.id + '_' + p.subPrestationName.id
    if(!prestations[index]) {
      prestations[index] = {
        category: p.prestation.categoryName,
        subCategory: p.prestation.subCategoryName,
        prestation: p.prestation.name,
        prestationId: p.prestation.id,
        subPrestationName: p.subPrestationName.name,
        subPrestationNameId: p.subPrestationName.id,
        subPrestationApsId: null,
        subPrestationExeId: null,
        subPrestationId: p.id,
        price: p.price,
        phase: '',
        hypothesisAps: '',
        oscarColumnAps: '',
        hypothesisExe: '',
        oscarColumnExe: ''
      }
    }

    if(p.phase) {
      prestations[index].phase = p.phase.name

      if(p.phase.name === 'APS') {
        prestations[index].subPrestationApsId = p.id
        prestations[index].oscarColumnAps = p.oscarColumn ? p.oscarColumn.name : ''
        prestations[index].hypothesisAps = p.hypothesis ? p.hypothesis : ''
      } else if (p.phase.name === 'EXE') {
        prestations[index].subPrestationExeId = p.id
        prestations[index].oscarColumnExe = p.oscarColumn ? p.oscarColumn.name : ''
        prestations[index].hypothesisExe = p.hypothesis
      }
    }
    if(p.locked) {
      prestations[index].locked = p.locked
    }

    prestations[index].oscarColumn = p.oscarColumn ? p.oscarColumn.name : ''

  });

  return Object.values(prestations)
}
