import AccountingNature from './accountingNature';
import AdministrationStatus from "./administrationStatus";
import App from "./app";
import Auth from "./auth";
import Budget from './budget';
import Category from './category';
import Comment from './comment';
import Dashboard from './dashboard';
import Direction from "./direction";
import Import from './import';
import Imputation from './imputation';
import Order from './order';
import OscarColumn from './oscarColumn';
import OscarSubProject from './oscarSubProject';
import OscarSubTask from './oscarSubTask';
import Otp from './otp';
import Phase from './phase';
import Prestation from './prestation';
import Project from './project';
import ReferenceBudget from './referenceBudget';
import ReferenceBudgetPrestation from './referenceBudgetPrestation';
import Region from "./region";
import Role from './role';
import Sheet from './sheet';
import SheetStatus from "./sheetStatus";
import SheetTemplate from "./sheetTemplate";
import SubPrestationName from './subPrestationName';
import Synthesis from './synthesis';
import User from './user';

export default {
  App,
  Auth,
  AdministrationStatus,
  Budget,
  Category,
  Comment,
  Dashboard,
  Direction,
  User,
  Role,
  Project,
  Otp,
  Order,
  OscarColumn,
  OscarSubProject,
  OscarSubTask,
  Import,
  Imputation,
  ReferenceBudget,
  ReferenceBudgetPrestation,
  Region,
  SheetStatus,
  Sheet,
  SheetTemplate,
  Synthesis,
  Prestation,
  Phase,
  SubPrestationName,
  AccountingNature
};
