import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  importSap: (data) => {
    return axios.post("/import/sap", data)
  },
  fetchImportSap(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/import/sap?${paginate.toString()}`).then(response => response.data);
  },
  importPrestations: (data) => {
    return axios.post("/prestations/import", data)
  },
  importOscarSubProjects: (data) => {
    return axios.post("/import/oscar-subprojects", data)
  },
  importOscarSubTasks: (data) => {
    return axios.post("/import/oscar-subtasks", data)
  },
  importIsf: (data) => {
    return axios.post("/import/isf", data)
  },
  fetchImportIsf(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/import/isf?${paginate.toString()}`).then(response => response.data);
  },
};
