import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchOscarSubTasks(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/oscar-subtasks?${paginate.toString()}`).then(response => response.data);
  },
  fetchOscarSubTaskNames(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/oscar-subtask-names?${paginate.toString()}`).then(response => response.data);
  },
};