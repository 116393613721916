import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchDirections(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/directions?${paginate.toString()}`).then(response => response.data);
  },

  fetchDirectionsAsList(callback) {
    axios.get('/directions').then((response) => {
      callback(response.data.content);
    });
  }
};
