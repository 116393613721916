<template>
  <span>
    <span @click="show = true">
      <slot name="activator"></slot>
    </span>
    <transition name="modal-background">
      <div v-if="show" class="modal-background"></div>
    </transition>
    <transition name="slide-fade">
      <div v-if="show" class="modal-placeholder" @click="close">
        <div class="modal-bg" @click.stop>
          <i class="delete is-pulled-right is-medium" @click="close"></i>
          <div class="header has-text-left is-size-3">
            <h2 class="has-text-weight-bold has-text-info">{{ title }}</h2>
          </div>
          <div class="content">
            <slot></slot>
          </div>
          <div class="toolbar">
            <slot name="toolbar">
              <button class="button is-medium" @click="close">Fermer</button>
              <button
                class="button has-background-warning has-text-white is-pulled-right is-medium"
                @click="create"
              >{{ action }}</button>
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </span>
</template>

<script>
export default {
  name: 'modal',
  props: {
    title: String,
    action: {
      type: String,
      default: 'Créer',
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    open() {
      this.show = true;
      this.$emit('open');
    },
    close() {
      this.show = false;
      this.$emit('close');
    },
    create() {
      this.$emit('create');
    },
  },
};
</script>

<style scoped>
.modal-placeholder {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  height: 100%;
}

.modal-bg {
  position: fixed;
  left: 40%;
  right: 0;
  top: 0;
  height: 100%;
  background-color: #fff;
  padding: 20px 20px;
  overflow: auto;
}

.close {
  position: absolute;
  right: 20px;
  font-size: 22px;
  color: #777;
}

.content {
  max-height: calc(100% - 150px);
  padding: 20px 10px;
}

.slide-fade-enter-active,
.modal-background-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-active,
.modal-background-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(700px);
}

.modal-background-enter,
.modal-background-leave-to {
  opacity: 0.5;
}

.header {
  border-bottom: 1px solid #dbdbdb;
}

.toolbar {
  border-top: 1px solid #dbdbdb;
  padding: 10px 10%;
}

.modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 35;
}
</style>
