import axios from "axios";

export default {
  fetchOrdersForProject(id, callback) {
    axios.get(`/projects/${id}/orders`).then(response => {
      return callback(response.data)
    });
  },
  fetchOrdersForSheet(id, callback) {
    axios.get(`/sheets/${id}/orders`).then(response => {
      return callback(response.data)
    });
  },
};
