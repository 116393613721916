<template>
  <field :edit="edit" :inline="inline" :label="label" :value="displayedValue" :readonly="readonly" :required="required">
    <ValidationProvider :rules="appliedRules" v-slot="{ errors }">
    <div class="select" :class="{'is-loading': loading}">
      <select @input="onSelect" :cy-data="`select-${label.replaceAll(/[ \']/g, '')}`">
        <option
          v-for="(option, index) in optionsArray"
          :key="index"
          :value="index"
          :selected="isSelected(option, index)">
          <slot :option="option">{{ option }}</slot>
        </option>
      </select>

    </div>
    <slot name="helper"></slot>
    <span class="message is-danger nowrap">{{ errors[0] }}</span>
    </ValidationProvider>
  </field>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'select-field',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      // eslint-disable-next-line
      type: Object | String,
      default: null,
    },
    options: {
      type: Function,
    },
    pkey: {
      type: String,
      default: 'id',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      val: null,
      optionsArray: [],
      loading: false,
      isKeyValueOfString: false,
    };
  },
  computed: {
    ...mapGetters({
      editMode: 'App/isEdition',
    }),
    displayedValue() {
      if (!this.val) {
        return '';
      }

      if (this.isKeyValueOfString) {
        return this.optionsArray[this.val];
      }

      return (typeof this.val === 'string' ? this.val : this.val.name);
    },
    appliedRules () {
      let rules = this.rules

      if (this.required){
        if(this.rules !== '') {
          rules = rules + '|'
        }

        rules = rules + 'required'
      }

      return rules
    }
  },
  mounted() {
    if(this.value) {
      this.val = this.value;
    }
    /*
    if (this.edit) {
      this.refresh();
    }
    if (this.editMode) {
      this.refresh();
    } */
    this.refresh();
  },
  watch: {
    value(val) {
      this.val = val;
    },
    editMode(val) {
      if (val) {
        this.refresh();
      }
    },
    edit(val) {
      if (val) {
        this.refresh();
      }
    },
  },
  methods: {
    refresh() {
      this.loading = true;
      this.options((data) => {
        this.loading = false;
        this.optionsArray = data;

        if (!Array.isArray(data)) {
          this.isKeyValueOfString = true;
        }

        // console.log(typeof this.val !== 'object' && !this.isKeyValueOfString && !this.optionsArray.includes(this.val))
        // console.log(typeof this.val !== 'object' && this.isKeyValueOfString && !this.optionsArray[this.val])
        // console.log(typeof this.val === 'object' && !this.isKeyValueOfString && !this.optionsArray.some(el => el[this.pkey] === this.val[this.pkey]))
        if (
          !this.val || 
          typeof this.val !== 'object' && !this.isKeyValueOfString && !this.optionsArray.includes(this.val) ||
          typeof this.val !== 'object' && this.isKeyValueOfString && !this.optionsArray[this.val] ||
          typeof this.val === 'object' && !this.isKeyValueOfString && !this.optionsArray.some(el => el[this.pkey] === this.val[this.pkey])
        ) {
          if (this.isKeyValueOfString) {
            this.$emit('input', Object.keys(this.optionsArray)[0]);
            return;
          }

          this.$emit('input', this.optionsArray[0]);
        }
      });
    },
    onSelect(event) {
      if (!this.isKeyValueOfString) {
        this.$emit('input', this.optionsArray[event.target.value]);
        return;
      }
      this.$emit('input', event.target.value);
    },
    isSelected(option, index) {
      if (!this.val) {
        return false;
      }

      if (this.isKeyValueOfString) {
        return this.val == index;
      }

      return ((typeof option === 'string' && option === this.val) || (typeof option[this.pkey] !== 'undefined' && option[this.pkey] === this.val[this.pkey]));
    },
  },
};
</script>

<style lang='scss' scoped>
</style>
