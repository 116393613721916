<template>
  <field
    :edit="edit"
    :inline="inline"
    :label="label"
    :value="val"
    :readonly="readonly"
    :required="required"
    type="percentage"
    :decimals="decimals"
  >
    <ValidationProvider :rules="appliedRules" v-slot="{ errors }">
    <input
      class="input"
      type="number"
      @input="input"
      step="1"
      v-model.trim="inputVal"
      :required="required"
      :name="internal_id"
      :data-vv-as="label"
      :class="{ error: errors[0]}"
      :min="min"
      :max="max"
      :cy-data="label ? 'input-' + label.replaceAll(/[ \'.]/g, '') : dataTest.replaceAll(/[ \.']/g, '')"
    >
    <font-awesome-icon class="p-icon has-text-info" icon="percentage" />
    <span class="message is-danger nowrap">{{ errors[0] }}</span>
    </ValidationProvider>
  </field>
</template>

<script>
import uid from 'uniqid';
import { debounce } from "lodash";

export default {
  name: 'percentage-field',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {},
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: String,
      default: '',
    },
    dataTest: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    decimals: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      val: '',
      internal_id: uid(),
      inputVal: ''
    };
  },
  mounted() {
    this.val = this.value;
    this.inputVal = (this.value * 100).toFixed(2);
  },
  watch: {
    value(val) {
      if((val * 100).toFixed(2) === 'NaN') return

      this.val = val;
      this.inputVal = (val * 100).toFixed(2);
    },
  },
  computed: {
    appliedRules () {
      let rules = this.rules

      if (this.required){
        if(this.rules !== '') {
          rules = rules + '|'
        }

        rules = rules + 'required'
      }

      return rules
    },
  },
  methods: {
    input: debounce(function refresh(event) {
      this.val = String(event.target.value / 100)
      this.$emit('input', this.val.trim());
    }, 500)
  },
};
</script>

<style lang='scss' scoped>
.input {
  max-width: 500px;
  min-width: 100px;
  padding-left: 22px;
}

.error {
  border: 2px solid red;
}
.p-icon {
  position: absolute;
  left: 8px;
  top: 8px;
}
</style>
