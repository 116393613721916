import axios from "axios";

const localStorageKey = `ocb_user_data_${process.env.VUE_APP_API_URL}`;

const Authentication = {
  namespaced: true,
  state: {
    token: null,
    user: {
      role: null
    },
    config: {}
  },
  getters: {
    isAuthenticated: state => {
      if (state.token) return true;
      return false;
    },
    getToken: state => state.token,
    getUser: state => state.user || {},
    getConfig: state => state.config,
    getRights: state => entity => {
      if (!state.user.role || !state.user.role.entities[entity]) {
        return {
          create: false,
          delete: false,
          read: false,
          update: false
        };
      }
      return state.user.role.entities[entity];
    },
    getOptions: state => state.user.role.options
  },
  actions: {
    setAuth({ commit }, auth) {
      commit("setAuth", auth);
    },
    checkAuth({ commit }) {
      const data = JSON.parse(localStorage.getItem(localStorageKey));
      commit("setAuth", data);
    },
    logout({ commit }) {
      commit("logout");
    }
  },
  mutations: {
    setAuth(state, data) {
      if (data == null) {
        return;
      }
      localStorage.setItem(localStorageKey, JSON.stringify(data));
      state.token = data.token;
      state.user = data.user;
      state.config = data.config;
      axios.defaults.headers.Authorization = `Bearer ${state.token}`;
    },
    logout(state) {
      localStorage.removeItem(localStorageKey);
      state.token = null;
      state.user = {};
      state.config = {};
      axios.defaults.headers.Authorization = null;
    }
  }
};

export default Authentication;
