export const EntitiesName = Object.freeze({
  AdministrationStatus: 'administration-status',
  Category: 'category',
  Direction: 'direction',
  ImportSap: 'import-sap',
  ImportIsf: 'import-isf',
  Imputation: 'imputation',
  Order: 'order',
  OscarSubProject: 'oscar-subproject',
  OscarColumn: 'oscar-column',
  OscarSubTask: 'oscar-subtask',
  OscarSubTaskName: 'oscar-subtask-name',
  Phase: 'phase',
  Pm: 'pm',
  Prestation: 'prestation',
  Project: 'project',
  Region: 'region',
  Role: 'role',
  Sheet: 'sheet',
  SheetStatus: 'sheet-status',
  SheetTemplate: 'sheet-template',
  SubPrestation: 'sub-prestation',
  SubPrestationName: 'subprestation-name',
  User: 'user',
  UserProject: 'user_project',
  Revenue: 'revenue',
});

export const Entities = [];
