// helper class passed to fetch callback for compatibility with Spring pagination features
export default class Paginate {
  filters = null;
  sort = null;
  page = null;
  size = null;

  constructor(paginate) {
    this.filters = paginate.filters;
    this.sort = paginate.sort;
    this.page = paginate.page;
    this.size = paginate.size;
  }

  filterParams = () =>
    Object.keys(this.filters)
      .map(
        k => `${encodeURIComponent(k)}=${encodeURIComponent(this.filters[k])}`
      )
      .join("&");

  sortParams = () => `sort=${this.sort.column},${this.sort.direction}`;

  paginationParams = () => `page=${this.page}&size=${this.size}`;

  toString = () => {
    const pagination = this.paginationParams();
    const search = this.filterParams();
    const sort = this.sortParams();
    return `${pagination || ""}${search ? `&${search}` : ""}${
      sort ? `&${sort}` : ""
    }`;
  };
}
