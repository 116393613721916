<template>
  <div class="comment-form">
    <div class="text">
      <TextareaField edit v-model="message" />
    </div>
    <div class="actions">
      <div class="options" v-if="!parentId">
        <checkbox-field
          label="Nécessite une action"
          :inline="true"
          edit
          v-model="active"
        />
        <text-field
          label="Action à réaliser"
          v-if="active"
          :inline="true"
          edit
          v-model="actionName"
        ></text-field>
        <AutocompleteField
          v-if="active"
          label="Responsable"
          v-model="responsible"
          :edit="true"
          :fetch="$Api.User.searchUser"
          displayField="name"
        />
        <date-field
          v-if="active"
          label="Date d'échéance"
          v-model="dueDate"
          :edit="true"
          data-test="sel-project-end-date"
        ></date-field>
      </div>
      <div class="buttons">
        <button class="button is-warning" @click="cancel">Annuler</button>
        <button
          class="button is-success"
          :disabled="!validForm"
          @click="validate"
        >
          Valider
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import CheckboxField from "../fields/CheckboxField.vue";
import TextareaField from "../fields/TextareaField.vue";
import TextField from "../fields/TextField.vue";
import AutocompleteField from "../fields/AutocompleteField.vue";

export default {
  components: { TextareaField, CheckboxField, TextField, AutocompleteField },
  name: "comment-form",
  props: {
    sheetId: null,
    parentId: null,
    prestationId: null,
    categoryId: null,
  },
  data() {
    return {
      message: "",
      active: false,
      responsible: null,
      dueDate: null,
      actionName: null,
    };
  },
  computed: {
    validForm() {
      if (!this.active) {
        return !!this.message;
      }

      return !(!this.message || !this.responsible || !this.dueDate);
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    validate() {
      const comment = {
        parent: this.parentId ? { id: this.parentId } : null,
        referenceBudgetPrestation: this.prestationId
          ? { id: this.prestationId }
          : null,
        message: this.message,
        active: this.active,
        action: this.active,
        responsible: this.responsible,
        dueDate: this.dueDate,
        actionName: this.actionName,
        category: this.categoryId ? { id: this.categoryId } : null,
      };
      if (this.parentId) {
        return axios.post(`/comments`, comment).then((response) => {
          if (response.data.success) {
            this.$emit("added", comment);
          }
        });
      }
      if (this.sheetId) {
        return axios
          .post(`/sheets/${this.sheetId}/comment`, comment)
          .then((response) => {
            if (response.data.success) {
              this.$emit("added", comment);
            }
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.comment-form {
  display: flex;
}
.text {
  flex: 1;
  & > * {
    width: 100%;
  }
}
.actions {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.7em;
  .options {
    flex: 2;
    padding-left: 10px;
    padding-bottom: 10px;
    width: 100%;
    & > * {
      justify-content: space-between;
    }
  }
  .buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}

tr .field {
  margin-bottom: 0.75rem !important;
}
</style>
