import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchRoles(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/roles?${paginate.toString()}`)
      .then(res => res.data)
  },
  fetchRolesAsList(callback) {
    axios.get('/roles').then((response) => {
      callback(response.data.content);
    });
  },
  fetchVisibilityTypes(callback) {
    const roleTypes = [
      'Accès à tous les éléments de la base',
      'Accès limité aux projets associés à l\'utilisateur',
      'Accès limité aux fiches affaire associées à l\'utilisateur',
      'Accès limité aux projets et aux fiches affaire associés à l\'utilisateur',
    ];
    if (typeof callback === 'function') {
      callback(roleTypes);
    }
    return roleTypes;
  },
};