import axios from "axios";
import PaginationBuilder from "../../data/Paginate";

export default {
  fetchSheets(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/sheets?${paginate.toString()}`)
      .then((response) => response.data);
  },

  fetchSheetsAsList(callback) {
    axios.get("/sheets").then((response) => {
      callback(response.data.content);
    });
  },
  fetchSheetsForProject(id, p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get("projects/" + id + "/sheets?" + paginate.toString())
      .then((response) => response.data);
  },
  fetchSheetsForProjectsAndSheetTemplates(
    projectsIds,
    sheetTemplatesIds,
    milestones,
    statusesIds,
    administrationStatusesIds,
    ownersIds,
    p
  ) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(
        `/sheets?projects=${projectsIds}&sheettemplates=${sheetTemplatesIds}&milestones=${milestones}&statuses=${statusesIds}&administrationStatuses=${administrationStatusesIds}&owners=${ownersIds}&${paginate.toString()}`
      )
      .then((response) => response.data);
  },
  fetchStatusesForProjectsAndSheetTemplates(
    projectsIds,
    sheetTemplatesIds,
    sheetsIds,
    milestones,
    administrationStatusesIds,
    ownersIds,
    p
  ) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(
        `/sheets/statuses?projects=${projectsIds}&sheettemplates=${sheetTemplatesIds}&selected=${sheetsIds}&milestones=${milestones}&administrationStatuses=${administrationStatusesIds}&owners=${ownersIds}&${paginate.toString()}`
      )
      .then((response) => response.data);
  },
  fetchAdministrationStatusesForProjectsAndSheetTemplates(
    projectsIds,
    sheetTemplatesIds,
    sheetsIds,
    milestones,
    statusesIds,
    ownersIds,
    p
  ) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(
        `/sheets/administration-statuses?projects=${projectsIds}&sheettemplates=${sheetTemplatesIds}&selected=${sheetsIds}&statuses=${statusesIds}&milestones=${milestones}&owners=${ownersIds}&${paginate.toString()}`
      )
      .then((response) => response.data);
  },
  fetchMilestonesForProjectsAndSheetTemplates(
    projectsIds,
    sheetTemplatesIds,
    sheetsIds,
    statusesIds,
    administrationStatusesIds,
    ownersIds,
    p
  ) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(
        `/sheets/milestones?projects=${projectsIds}&sheettemplates=${sheetTemplatesIds}&selected=${sheetsIds}&statuses=${statusesIds}&administrationStatuses=${administrationStatusesIds}&owners=${ownersIds}&${paginate.toString()}`
      )
      .then((response) => response.data);
  },
  fetchSheetOwnersForProjectsAndSheetTemplates(
    projectsIds,
    sheetTemplatesIds,
    sheetsIds,
    milestones,
    statusesIds,
    administrationStatusesIds,
    p
  ) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(
        `/sheets/owners?projects=${projectsIds}&sheettemplates=${sheetTemplatesIds}&selected=${sheetsIds}&milestones=${milestones}&statuses=${statusesIds}&administrationStatuses=${administrationStatusesIds}&${paginate.toString()}`
      )
      .then((response) => response.data);
  },
  fetchBudgets(id, callback) {
    return axios.get("/sheets/" + id + "/budgets").then((response) => {
      return callback(response.data);
    });
  },
  fetchEventsForSheet(id, callback) {
    return axios.get("/sheets/" + id + "/events").then((response) => {
      return callback(response.data);
    });
  },
  fetchSheetVm(id, callback) {
    axios.get(`/sheets/${id}/vm`).then((response) => {
      return callback(response.data);
    });
  },
  fetchPeriods() {
    return axios
      .get('/sheets/periods')
      .then((response) => response.data);
  },
  fetchSheetsSynthesis(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/sheets/synthesis?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchGeneralSheet(params, callback) {
    return axios.get(`/sheets/vm?${params}`).then((response) => {
      return callback(response.data);
    });
  },
  fetchBudgetControls(id, callback) {
    return axios.get(`/sheets/${id}/budget-controls`).then((response) => {
      return callback(response.data);
    });
  },
  downloadBudgetControl(projectId, sheetId) {
    axios.get(`/sheets/${sheetId}/project/${projectId}/pdf?`);
  },
  getMonths(callback) {
    let months = {
      1: "Janvier",
      2: "Février",
      3: "Mars",
      4: "Avril",
      5: "Mai",
      6: "Juin",
      7: "Juillet",
      8: "Août",
      9: "Septembre",
      10: "Octobre",
      11: "Novembre",
      12: "Décembre",
    };

    if (typeof callback === "function") {
      return callback(months);
    }
    return months;
  },
  getYears(callback) {
    const currentYear = new Date().getFullYear();
    const years = {};

    for (let year = currentYear - 10; year <= currentYear; year++) {
      years[year.toString()] = year.toString();
    }

    const sortedYears = Object.keys(years).sort((a, b) => b - a);

    if (typeof callback === "function") {
      return callback(sortedYears);
    }
    return sortedYears;
  },
  fetchControlTypes(p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/control-types?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchPreviousNextSheets(sheetId, p) {
    const paginate = new PaginationBuilder(p);
    return axios
      .get(`/sheets/${sheetId}/prevnext?${paginate.toString()}`)
      .then((response) => response.data);
  },
  fetchCommentsForSheet(id) {
    return axios
      .get(`/sheets/${id}/comments`)
      .then((response) => {
        return response.data;
      });
  },
};
