<template>
  <div class="field" :class="{'is-horizontal': inline}" :style="{justifyContent: (type == 'number' || type == 'price') ? 'flex-end' : type == 'percentage' ? 'center' : 'flex-start'}">
    <div v-if="label !== ''" class="input-label" :class="{inline: inline}">{{ label + ((edit || globalEdit) && required ? '*' : '') }}</div>
    <slot v-if="readonly || (!edit && globalEdit === false)" name="value">
      <span v-if="isCheckbox">
        <font-awesome-icon :icon="(isChecked) ? 'check' : 'times'" :class="{ 'has-text-success': isChecked, 'has-text-danger': !isChecked}"/>
      </span>
      <span v-if="type === 'number'" >{{ value | number(decimals) }}</span>
      <span v-if="type === 'percentage'" >{{ value | percentage(decimals) }}</span>
      <span v-if="type === 'price'" >{{ value | priceEUR }}</span>
      <span v-if="!isCheckbox && type !== 'number' && type !== 'percentage' && type !== 'price'" >{{ value }}</span>
    </slot>
    <slot v-if="readonly || (edit || globalEdit === true)"></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'field',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {},
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isCheckbox: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    decimals: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      globalEdit: 'App/isEdition',
    }),
  },
};
</script>

<style lang='scss' scoped>
.field {
  width: 100%;
  margin-right: 4px;
  align-items: center;
  margin-bottom: 1em;
  position: relative;
}

.input-label {
  font-weight: bold;
  vertical-align: top;
  padding-right: 4px;
}

.inline {
  display: inline-block;
  flex-shrink: 0;
  min-width: 30%;
  border-bottom: 1px dotted #ddd;
}
</style>
