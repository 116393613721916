'use strict';

import router from '@/router';
import store from '@/store';
import axios from 'axios';
import Vue from 'vue';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.defaults.baseURL = process.env.VUE_APP_API_URL || '/api';

axios.interceptors.request.use(
  function (config) {
    // globally add a loading status
    store.dispatch('App/setLoading', true);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // globally remove loading status
    store.dispatch('App/setLoading', false);
    return response;
  },
  function (error) {
    // globally remove loading status
    store.dispatch('App/setLoading', false);

    if (error.response) {
      const requestId =
        error.response.headers['x-request-id'] || 'no-request-id';
      switch (error.response.status) {
        case 500:
          // eslint-disable-next-line
          alert('Une erreur est survenue. RequestId: ' + requestId);
          break;
        case 400: {
          // Validation errors
          const err = error.response.data;

          // eslint-disable-next-line
          alert(err.error + ' RequestId: ' + requestId);
          console.error(err);
          break;
        }
        case 422:
          break;

        case 403:
        case 401:
          store.dispatch('Auth/logout');
          router.replace({ path: '/login' });
          break;
        default:
          // eslint-disable-next-line
          alert(
            `Une erreur est survenue. ${error.response.data.path} a répondu avec le code d'erreur ${error.response.status} ${error.response.data.error} "${error.response.data.message}", RequestId: ${requestId}`
          );
          break;
      }
    }

    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
  Vue.axios = axios;
  window.axios = axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios;
      },
    },
    $axios: {
      get() {
        return axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
