<template>
  <div id="app">
    <header v-if="isAuthenticated">
      <navbar></navbar>
    </header>
    <div class="content-wrapper">
      <router-view />
    </div>
    <footer>
      Outil de Contrôle Budgétaire <br />
      <br />
      Axione OCB v{{ env.VUE_APP_VERSION }} by
      <a href="https://aliengen.com#axocb">AlienGen</a><br />
      <span v-if="!error">
        <span v-if="api_version">API v{{ api_version }}</span>
        <span v-if="!api_version">Loading...</span>
      </span>
      <span v-if="error">{{ error }}</span>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from "@/components/layout/Navbar";

export default {
  components: {
    Navbar,
  },
  name: "App",
  data() {
    return {
      api_version: null,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "Auth/isAuthenticated",
      env: "App/getEnv",
    }),
  },
  mounted() {
    this.loadApiVersion();
  },
  methods: {
    loadApiVersion() {
      return this.$Api.App.loadApiVersion()
        .then((ver) => {
          this.api_version = ver;
        })
        .catch((error) => {
          this.error = error;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main";

html {
  height: 100%;
}

:root {
  // CSS variables need to be wrapped in SASS interpolator
  --axione-orange: #{$axione-orange};
  --axione-dark-grey: #{$axione-dark-grey};
  --axione-blue: #{$axione-blue};
}

body {
  height: inherit;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

#app {
  flex: 1;
  display: flex;
  flex-direction: column;
}

footer,
footer a {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  font-size: 12px;
}

.container {
  padding-top: 75px;
}

.container .header {
  margin-bottom: 15px;
}

.container .header h1 {
  display: inline-block;
  font-weight: bold;
  font-size: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container .header button {
  vertical-align: super;
  color: white;
}

.content .title {
  padding: 10px;
  font-size: 25px;
  padding-left: 23px;
  margin: -20px;
  margin-bottom: 23px;
  color: white;
}

.content .title button {
  margin-left: 30px;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>


