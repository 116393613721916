import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchOtps(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/otps?${paginate.toString()}`).then(response => response.data);
  },

  fetchOtpsAsList(callback) {
    axios.get('/otps').then((response) => {
      callback(response.data.content);
    });
  },
  fetchAvailableOtps(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`otps/available?${paginate.toString()}`).then(response => response.data);
  },

};
