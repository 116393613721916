import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchPhases(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/phases?${paginate.toString()}`)
      .then(res => res.data)
  },
  fetchPhasesAsList(callback) {
    axios.get('/phases').then((response) => {
      callback(response.data.content);
    });
  },
};