<template>
  <field
    :edit="edit"
    :inline="inline"
    :label="label"
    :value="selected && selected[displayField]"
    :readonly="readonly"
    :required="required"
  >
    <ValidationProvider :rules="required ? 'required' : ''" v-slot="{ errors }">
    <b-autocomplete
      v-model="search"
      :data="options"
      @typing="fetchOptions"
      :field="displayField"
      clearable
      :loading="isFetching"
      @select="option => selected = option"
      :cy-data="label ? 'autocomplete-' + label.replaceAll(/[ \'.]/g, '') : dataTest.replaceAll(/[ \.']/g, '')"
    >
      <template #empty>Aucun résultat</template>
      <template slot-scope="item"><slot><div>{{ item.option[displayField] }}</div></slot></template>
    </b-autocomplete>
    <span class="message is-danger nowrap">{{ errors[0] }}</span>
    </ValidationProvider>
  </field>
</template>

<script>
import Field from './Field.vue'
import { debounce } from "lodash";

export default {
  components: { Field },
  name: 'autocomplete-field',
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: ''
    },
    displayField: {
      type: String,
      default: 'id'
    },
    fetch: {
      type: Function,
      default: () => {}
    },
    edit: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: '',
      options: [],
      selected: null,
      isFetching: false
    }
  },
  mounted() {
    if(!this.value) {
      return
    }
    this.selected = this.value
  },
  watch: {
    value() {
      this.selected = this.value
    },
    selected() {
      this.$emit('input', this.selected)
    }
  },
  methods: {
    fetchOptions: debounce(function () {
      this.isFetching = true

      this.fetch(this.search)
        .then((res) => {
          this.options = res
        })
        .finally(() => {
          this.isFetching = false
        })
    }, 500)
  }
}
</script>