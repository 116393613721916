import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchSheetTemplates(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/sheet-templates?${paginate.toString()}`).then(response => response.data);
  },

  fetchSheetTemplatesAsList(callback) {
    axios.get('/sheet-templates').then((response) => {
      callback(response.data.content);
    });
  },

  fetchPrestationSheetTemplates(id) {
    return axios.get(`/sheet-templates/${id}/prestations`)
      .then(response => {
        return groupBySubPrestation(response.data)
      });
  },

  fetchSheetTemplatesForProjects(ids, p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/sheet-templates?projects=${ids}&${paginate.toString()}`)
      .then(response => response.data);
  },
};


function groupBySubPrestation(data) {
  let prestations = {}

  data.forEach((p) => {
    const index = p.subPrestation.prestation.id + '_' + p.subPrestation.subPrestationName.id
    if(!prestations[index]) {
      prestations[index] = {
        sheetTemplate: { id: p.sheetTemplateId },
        ids: [],
        subPrestations: [],
        categoryName: p.categoryName,
        subCategoryName: p.subCategoryName,
        prestationName: p.prestationName,
        subPrestationName: p.subPrestationName ,
        accountingNatures: p.accountingNatures || [],
        oscarSubTaskNames: p.oscarSubTaskNames || [],
        prestationId: p.prestationId,
        sheetTemplateId: p.sheetTemplateId,
        usedInABudget: p.usedInABudget,
        ordering: p.ordering
      }
    }
    if(prestations[index].accountingNatures.length === 0)
      prestations[index].accountingNatures = p.accountingNatures || [];

    if(prestations[index].oscarSubTaskNames.length === 0)
      prestations[index].oscarSubTaskNames = p.oscarSubTaskNames || [];
    prestations[index].ids.push(p.id)
    prestations[index].subPrestations.push(p.subPrestation)
  });

  return Object.values(prestations)
}
