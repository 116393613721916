import axios from "axios";
import PaginationBuilder from '../../data/Paginate';

export default {
  fetchImputations(p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`/imputations?${paginate.toString()}`)
      .then(res => res.data)
  },
  fetchImputationsAsList(callback) {
    axios.get('/imputations').then((response) => {
      callback(response.data.content);
    });
  },
  fetchAvailableImputations(projectId, p) {
    const paginate = new PaginationBuilder(p);
    return axios.get(`projects/${projectId}/imputations/available?${paginate.toString()}`)
      .then(res => res.data)
  },
};