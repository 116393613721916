<template>
  <field label="Tout sélectionner">
    <input type="checkbox" :checked="isEntityTypeAllSelected" @click="e => selectAllEntities(e.target.checked)" class="switch is-success is-rounded" :id="id" />
    <label :for="id"></label>
  </field>
</template>
<script>
import ui from 'uniqid';

export default {
  name: 'select-all',
  props: ['entities', 'value', 'pKey'],
  data() {
    return {
      val: this.value,
      id: ui(),
    };
  },
  watch: {
    value(val) {
      this.val = val;
    },
  },
  computed: {
    isEntityTypeAllSelected() {
      return this.val && this.val.length === this.entities.length;
    },
  },
  methods: {
    selectAllEntities(all) {
      let entities;
      if (!this.pKey && typeof this.entities[this.pKey] === 'undefined') {
        entities = this.entities;
      } else {
        entities = this.entities.map(e => e[this.pKey]);
      }
      const valued = all ? entities : [];
      this.$emit('input', valued);
    },
    selectAllButtonText() {
      return this.isEntityTypeAllSelected ? 'Tout sélectionner' : 'Tout déselectionner';
    },
  },
};
</script>
<style lang='scss' scoped>
</style>
